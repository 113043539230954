<template>
  <TourRose/>
</template>

<script>
// @ is an alias to /src
import TourRose from '@/components/la-tour-rose/TourRose.vue'

export default {
  name: 'TourRosePage',
  components: {
    TourRose
  }
}
</script>
