<template>
  <CalculLitteral/>
</template>

<script>
// @ is an alias to /src
import CalculLitteral from '@/components/mathematiques-12-15/CalculLitteral.vue'

export default {
  name: 'CalculLitteralPage',
  components: {
    CalculLitteral
  }
}
</script>
