<template>
  <div class="box_title">
    <h1>Les formes superposées</h1>
  </div>
  <div class="container">

    <button @click="shuffleAll" class="btn" type="button" name="button">Ranger</button>

    <button @click="change_mode" class="btn" type="button" name="button">Mode : {{ this.mode.name }}</button>

    <div id="tapis" class="tapis">
      <!-- Carpet  -->
      <img src='../../assets/images/fonds/fond-formes-superposees.png' id="img_tapis" class="img_tapis" draggable="false"
      @mousemove.left="onMouseMove"
      @touchmove="onMouseMove"
      >
      <!-- Elements  -->
      <img v-for="index in 90"  :key="index" :id="'poly_' + [index]" class="poly_1"
      @click="onMouseMove;rotateElement($event);"
      @mousemove.left="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup.left="onUp"
      @mousedown.left="onDown"
      draggable="false"
      :src="require('../../assets/images/formes-superposees/' + this['poly_' + index].link + '.png')"
      :style="{ left: this['poly_' + index].pos_x * this.tapis.width + 'px', top: this['poly_' + index].pos_y * this.tapis.height + 'px' }">
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormesSuperposees',
  mounted() {

    const io = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.getTapisPosition()
      })
    })
    const tapis_element = document.getElementById('tapis');
    io.observe(tapis_element);

    window.addEventListener('load', () => {
      setTimeout( () => this.initPositions(), 300);
    })

  },
  data() {
    return {
      mode : {
        value : 'move',
        name : 'Souris',
        bool : null,
        element_id : null
      },
      tapis : {
        top    : 0,
        right  : 0,
        bottom : 0,
        left   : 0,
        height : 0,
        width  : 0,
      },
      mouse_position : {
        pos_x : 0,
        pos_y : 0
      },
      poly_1: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_2: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_3: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_4: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_5: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_6: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_7: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },

      poly_8: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_9: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_10: {
        link : 'carre-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_11: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_12: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_13: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_14: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_15: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_16: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_17: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },

      poly_18: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_19: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_20: {
        link : 'carre-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_21: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_22: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_23: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_24: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_25: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_26: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_27: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },

      poly_28: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_29: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_30: {
        link : 'carre-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_31: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_32: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_33: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_34: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_35: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_36: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_37: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },

      poly_38: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_39: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_40: {
        link : 'rond-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_41: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_42: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_43: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_44: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_45: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_46: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_47: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },

      poly_48: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_49: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_50: {
        link : 'rond-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_51: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_52: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_53: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_54: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_55: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_56: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_57: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },

      poly_58: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_59: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_60: {
        link : 'rond-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_61: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_62: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_63: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_64: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_65: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_66: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_67: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },

      poly_68: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_69: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_70: {
        link : 'triangle-bleu',
        pos_x: 0,
        pos_y: 0
      },
      poly_71: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_72: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_73: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_74: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_75: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_76: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_77: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },

      poly_78: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_79: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_80: {
        link : 'triangle-jaune',
        pos_x: 0,
        pos_y: 0
      },
      poly_81: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_82: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_83: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_84: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_85: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_86: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_87: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },

      poly_88: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_89: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      poly_90: {
        link : 'triangle-rouge',
        pos_x: 0,
        pos_y: 0
      },
      element : {
        clicked : null,
      },
      shuffle_values : {
        value : null,
      }
    }
  },
  methods: {
    change_mode(){
      var current_val = this.mode.value;
      this.mode.value = (current_val == 'move') ? 'rotation' : 'move';
      this.mode.name  = (current_val == 'move') ? 'Rotation' : 'Souris';
    },
    initPositions(){
      var k = 0;
      for (var j = 0; j < 90; j++) {

        if ( j % 10 == 0 ) {


          var arr = [1,2,3,4,5,6,7,8,9,10];

          var pos = [
            [0.135, 0.13],
            [1.09, 0.13],
            [2.023, 0.13],
            [0.428, 0.13],
            [1.38, 0.13],
            [2.325, 0.13],
            [0.730, 0.13],
            [1.67, 0.13],
            [2.620, 0.13]
          ];


          var lf = pos[k][0] * this.tapis.width/3;
          var raw = pos[k][1] * this.tapis.height/2;

          k = k+1;

          this['poly_' + (j + arr[0])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[0])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[1])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[1])].pos_y = this.percentY(raw);


          this['poly_' + (j + arr[2])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[2])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[3])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[3])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[4])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[4])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[5])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[5])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[6])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[6])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[7])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[7])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[8])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[8])].pos_y = this.percentY(raw);

          this['poly_' + (j + arr[9])].pos_x = this.percentX(lf);
          this['poly_' + (j + arr[9])].pos_y = this.percentY(raw);

          document.getElementById('poly_' + (j + arr[0])).style.zIndex = '110';
          document.getElementById('poly_' + (j + arr[1])).style.zIndex = '109';
          document.getElementById('poly_' + (j + arr[2])).style.zIndex = '108';
          document.getElementById('poly_' + (j + arr[3])).style.zIndex = '107';
          document.getElementById('poly_' + (j + arr[4])).style.zIndex = '106';
          document.getElementById('poly_' + (j + arr[5])).style.zIndex = '105';
          document.getElementById('poly_' + (j + arr[6])).style.zIndex = '104';
          document.getElementById('poly_' + (j + arr[7])).style.zIndex = '103';
          document.getElementById('poly_' + (j + arr[8])).style.zIndex = '102';
          document.getElementById('poly_' + (j + arr[9])).style.zIndex = '101';
        }
      }
    },
    getTapisPosition() {
      var tapis_element = document.getElementById('img_tapis');
      const rect        = tapis_element.getBoundingClientRect();
      this.tapis.top    = rect.top;
      this.tapis.right  = rect.right;
      this.tapis.left   = rect.left;
      this.tapis.width  = rect.width;
      this.tapis.bottom = rect.bottom;
      this.tapis.height = rect.height;
    },
    onDown(e) {
      if (this.mode.value === 'move') {
        var element    = e.currentTarget;
        this.element.clicked = element.id;
      }
    },
    moveAt() {
      var element_id = this.element.clicked;
      if (element_id && this.element.clicked !== null) {
        var element     = document.getElementById(element_id).getBoundingClientRect();
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.width/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.height/2));

        if ((this[element_id].pos_x * this.tapis.width) < 0) {
          this[element_id].pos_x = 0 ;
        }
        if ((this[element_id].pos_y * this.tapis.height) < 0) {
          this[element_id].pos_y = 0 ;
        }

        if ((this[element_id].pos_x * this.tapis.width) + element.width  > this.tapis.width) {
          this[element_id].pos_x = this.percentX(this.tapis.width -  element.width);
        }

        if ((this[element_id].pos_y * this.tapis.height) + element.height > this.tapis.height) {
          this[element_id].pos_y = this.percentY(this.tapis.height - element.height);
        }
      }
    },
    onUp(e) {
      if (this.mode.value === 'move') {
        // e.preventDefault();
        var element    = e.currentTarget;
        var element_id = element.id;
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.offsetWidth/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.offsetHeight/2));
        this.element.clicked = null;
      }

    },
    onMouseMove(e) {
      e.preventDefault();
        var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
        var element    = e.currentTarget;
        this.mouse_position.pox_x = evt.pageX;
        this.mouse_position.pox_y = evt.pageY;
        if (element !== 'undefined') {
          this.moveAt();
        }
    },
    percentX(x){
      return Math.round(1000 * x/this.tapis.width)/1000;
    },
    percentY(y){
      return Math.round(10000 * y/this.tapis.height)/10000;
    },
    shuffleAll(){
      this.shuffle_values.value = true;
      this.initPositions();

      setTimeout( () => {
        this.shuffle_values.value = null;
      }, 300);
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
        }

        return array;
      }
      ,
      rotateElement(e){
        if (this.mode.value !== 'move') {
          var element    = e.currentTarget;
          var element_id = element.id;
          this.mode.element_id = element_id;
          this.rotateElementLoop();
          this.mode.element_id = null;
        }
      },
      rotateElementLoop(){
        console.log('rotateElementLoop');
        var rotated;
        var div    = document.getElementById(this.mode.element_id);
        // var deg = rotated !== 'undefined' ? 0 : 66;
        var deg = 45;

        if (div.style.transform.match(/\d+/)) {
          deg = deg + parseInt(div.style.transform.match(/\d+/));
        }

        div.style.webkitTransform = 'rotate('+deg+'deg)';
        div.style.mozTransform    = 'rotate('+deg+'deg)';
        div.style.msTransform     = 'rotate('+deg+'deg)';
        div.style.oTransform      = 'rotate('+deg+'deg)';
        div.style.transform       = 'rotate('+deg+'deg)';

        rotated = !rotated;
        return this.mode.bool;
      }
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .container{
    width: calc(100% - 40px);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .tapis{
    display: block;
    position: relative;
    overflow: hidden;
  }
  .tapis img:first-child {
    margin: 0;
    width: 100%;
    height: auto;
    /* pointer-events: none; */
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .poly_1{
    display: block;
    /* width: 22.851%; */
    /* height: 20%; */
    /* width: auto; */
    height: auto;
    position: absolute;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

   -webkit-transform: scale(1); /* Saf3.1+, Chrome */
    -moz-transform: scale(1); /* FF3.5+ */
     -ms-transform: scale(1); /* IE9 */
      -o-transform: scale(1); /* Opera 10.95+ */
         transform: scale(1);
            /* IE6–IE9 */
            filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand');
  }
  .btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    margin: 20px;
  }
  .btn:hover {
    background-color: white; /* Green */
    color: #000;
    border: solid 1px #4CAF50;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

  #poly_1{
    height: 1.4%;
  }
  #poly_2{
    height: 2.8%;
  }
  #poly_3{
    height: 4.2%;
  }
  #poly_4{
    height: 5.6%;
  }
  #poly_5{
    height: 7%;
  }
  #poly_6{
    height: 8.4%;
  }
  #poly_7{
    height: 9.8%;
  }
  #poly_8{
    height: 11.2%;
  }
  #poly_9{
    height: 12.6%;
  }
  #poly_10{
    height: 14%;
  }
  #poly_11{
    height: 1.4%;
  }
  #poly_12{
    height: 2.8%;
  }
  #poly_13{
    height: 4.2%;
  }
  #poly_14{
    height: 5.6%;
  }
  #poly_15{
    height: 7%;
  }
  #poly_16{
    height: 8.4%;
  }
  #poly_17{
    height: 9.8%;
  }
  #poly_18{
    height: 11.2%;
  }
  #poly_19{
    height: 12.6%;
  }
  #poly_20{
    height: 14%;
  }
  #poly_21{
    height: 1.4%;
  }
  #poly_22{
    height: 2.8%;
  }
  #poly_23{
    height: 4.2%;
  }
  #poly_24{
    height: 5.6%;
  }
  #poly_25{
    height: 7%;
  }
  #poly_26{
    height: 8.4%;
  }
  #poly_27{
    height: 9.8%;
  }
  #poly_28{
    height: 11.2%;
  }
  #poly_29{
    height: 12.6%;
  }
  #poly_30{
    height: 14%;
  }
  #poly_31{
    height: 1.4%;
  }
  #poly_32{
    height: 2.8%;
  }
  #poly_33{
    height: 4.2%;
  }
  #poly_34{
    height: 5.6%;
  }
  #poly_35{
    height: 7%;
  }
  #poly_36{
    height: 8.4%;
  }
  #poly_37{
    height: 9.8%;
  }
  #poly_38{
    height: 11.2%;
  }
  #poly_39{
    height: 12.6%;
  }
  #poly_40{
    height: 14%;
  }
  #poly_41{
    height: 1.4%;
  }
  #poly_42{
    height: 2.8%;
  }
  #poly_43{
    height: 4.2%;
  }
  #poly_44{
    height: 5.6%;
  }
  #poly_45{
    height: 7%;
  }
  #poly_46{
    height: 8.4%;
  }
  #poly_47{
    height: 9.8%;
  }
  #poly_48{
    height: 11.2%;
  }
  #poly_49{
    height: 12.6%;
  }
  #poly_50{
    height: 14%;
  }
  #poly_51{
    height: 1.4%;
  }
  #poly_52{
    height: 2.8%;
  }
  #poly_53{
    height: 4.2%;
  }
  #poly_54{
    height: 5.6%;
  }
  #poly_55{
    height: 7%;
  }
  #poly_56{
    height: 8.4%;
  }
  #poly_57{
    height: 9.8%;
  }
  #poly_58{
    height: 11.2%;
  }
  #poly_59{
    height: 12.6%;
  }
  #poly_60{
    height: 14%;
  }
  #poly_61{
    height: 1.22%;
  }
  #poly_62{
    height: 2.44%;
  }
  #poly_63{
    height: 3.66%;
  }
  #poly_64{
    height: 4.88%;
  }
  #poly_65{
    height: 6.1%;
  }
  #poly_66{
    height: 7.32%;
  }
  #poly_67{
    height: 8.54%;
  }
  #poly_68{
    height: 9.76%;
  }
  #poly_69{
    height: 10.98%;
  }
  #poly_70{
    height: 12.2%;
  }
  #poly_71{
    height: 1.22%;
  }
  #poly_72{
    height: 2.44%;
  }
  #poly_73{
    height: 3.66%;
  }
  #poly_74{
    height: 4.88%;
  }
  #poly_75{
    height: 6.1%;
  }
  #poly_76{
    height: 7.32%;
  }
  #poly_77{
    height: 8.54%;
  }
  #poly_78{
    height: 9.76%;
  }
  #poly_79{
    height: 10.98%;
  }
  #poly_80{
    height: 12.2%;
  }
  #poly_81{
    height: 1.22%;
  }
  #poly_82{
    height: 2.44%;
  }
  #poly_83{
    height: 3.66%;
  }
  #poly_84{
    height: 4.88%;
  }
  #poly_85{
    height: 6.1%;
  }
  #poly_86{
    height: 7.32%;
  }
  #poly_87{
    height: 8.54%;
  }
  #poly_88{
    height: 9.76%;
  }
  #poly_89{
    height: 10.98%;
  }
  #poly_90{
    height: 12.2%;
  }
  </style>
