<template>
  <SoustractionStatique/>
</template>

<script>
// @ is an alias to /src
import SoustractionStatique from '@/components/SoustractionStatique.vue'

export default {
  name: 'SoustractionStatiquePage',
  components: {
    SoustractionStatique
  }
}
</script>
