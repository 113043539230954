<template>
  <Botanique1/>
</template>

<script>
// @ is an alias to /src
import Botanique1 from '@/components/botanique-1/Botanique1.vue'

export default {
  name: 'Botanique1Page',
  components: {
    Botanique1
  }
}
</script>
