<template>
  <div class="box_title">
    <h1>La 3ème boîte des couleurs</h1>
  </div>
  <div class="container">
    <button @click="initPositions" class="btn" type="button" name="button">Ranger</button>
    <div id="tapis" class="tapis">
      <!-- Carpet  -->
      <img src='../../assets/images/fonds/fond.png' id="img_tapis" class="img_tapis" draggable="false"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      >
      <!-- Elements  -->
      <img v-for="index in 63"  :key="index" :id="'couleur_' + [index]" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      :src="require('../../assets/images/boite-des-couleurs-3/' + this['couleur_' + index].link + '.png')"
      :style="{ left: this['couleur_' + index].pos_x * this.tapis.width + 'px', top: this['couleur_' + index].pos_y * this.tapis.height + 'px' }">

    </div>
  </div>
</template>

<script>
export default {
  name: 'BoiteDesCouleurs2',
  mounted() {

    const io = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.getTapisPosition()
      })
    })
    const tapis_element = document.getElementById('tapis');
    io.observe(tapis_element);

    window.addEventListener('load', () => {
      setTimeout( () => this.initPositions(), 300);
    })

  },
  data() {
    return {
      tapis : {
        top    : 0,
        right  : 0,
        bottom : 0,
        left   : 0,
        height : 0,
        width  : 0,
      },
      mouse_position : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_1: {
        link : 'bleu-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_2: {
        link : 'bleu-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_3: {
        link : 'bleu-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_4: {
        link : 'bleu-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_5: {
        link : 'bleu-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_6: {
        link : 'bleu-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_7: {
        link : 'bleu-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_8: {
        link : 'gris-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_9: {
        link : 'gris-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_10: {
        link : 'gris-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_11: {
        link : 'gris-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_12: {
        link : 'gris-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_13: {
        link : 'gris-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_14: {
        link : 'gris-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_15: {
        link : 'jaune-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_16: {
        link : 'jaune-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_17: {
        link : 'jaune-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_18: {
        link : 'jaune-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_19: {
        link : 'jaune-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_20: {
        link : 'jaune-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_21: {
        link : 'jaune-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_22: {
        link : 'marron-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_23: {
        link : 'marron-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_24: {
        link : 'marron-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_25: {
        link : 'marron-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_26: {
        link : 'marron-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_27: {
        link : 'marron-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_28: {
        link : 'marron-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_29: {
        link : 'orange-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_30: {
        link : 'orange-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_31: {
        link : 'orange-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_32: {
        link : 'orange-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_33: {
        link : 'orange-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_34: {
        link : 'orange-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_35: {
        link : 'orange-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_36: {
        link : 'rose-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_37: {
        link : 'rose-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_38: {
        link : 'rose-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_39: {
        link : 'rose-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_40: {
        link : 'rose-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_41: {
        link : 'rose-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_42: {
        link : 'rose-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_43: {
        link : 'rouge-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_44: {
        link : 'rouge-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_45: {
        link : 'rouge-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_46: {
        link : 'rouge-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_47: {
        link : 'rouge-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_48: {
        link : 'rouge-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_49: {
        link : 'rouge-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_50: {
        link : 'vert-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_51: {
        link : 'vert-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_52: {
        link : 'vert-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_53: {
        link : 'vert-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_54: {
        link : 'vert-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_55: {
        link : 'vert-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_56: {
        link : 'vert-7',
        pos_x: 0,
        pos_y: 0
      },

      couleur_57: {
        link : 'violet-1',
        pos_x: 0,
        pos_y: 0
      },
      couleur_58: {
        link : 'violet-2',
        pos_x: 0,
        pos_y: 0
      },
      couleur_59: {
        link : 'violet-3',
        pos_x: 0,
        pos_y: 0
      },
      couleur_60: {
        link : 'violet-4',
        pos_x: 0,
        pos_y: 0
      },
      couleur_61: {
        link : 'violet-5',
        pos_x: 0,
        pos_y: 0
      },
      couleur_62: {
        link : 'violet-6',
        pos_x: 0,
        pos_y: 0
      },
      couleur_63: {
        link : 'violet-7',
        pos_x: 0,
        pos_y: 0
      },
      element : {
        clicked : null,
      }
    }
  },
  methods: {
    initPositions(){

      var element_width = document.getElementById('couleur_1').getBoundingClientRect().width;
      var element_mg_left = document.getElementById('couleur_1').getBoundingClientRect().width / 3;

      var element_height = document.getElementById('couleur_1').getBoundingClientRect().height;
      var element_mg_top = document.getElementById('couleur_1').getBoundingClientRect().height / 5;

      var k = 0;

      for (var j = 1; j < 60; j++) {

        if ( j % 7 == 0 || j == 1 ) {
          var arr = j == 1 ? [0,1,2,3,4,5,6] : [1,2,3,4,5,6,7];
          this.shuffle(arr);

          var lg = element_mg_left;
          var col = 0;
          var pos = [1,2,3,1,2,3,1,2,3];

          if (k > 2 && k < 6) {
            col = this.tapis.width / 3;
          }
          else if(k >= 6) {
            col = 2 * this.tapis.width / 3;
          }


          this['couleur_' + (j + arr[0])].pos_x = this.percentX( col + (lg * 1));
          this['couleur_' + (j + arr[0])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          this['couleur_' + (j + arr[1])].pos_x = this.percentX( col + (lg * 2));
          this['couleur_' + (j + arr[1])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          this['couleur_' + (j + arr[2])].pos_x = this.percentX( col + (lg * 3));
          this['couleur_' + (j + arr[2])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          this['couleur_' + (j + arr[3])].pos_x = this.percentX( col + (lg * 4));
          this['couleur_' + (j + arr[3])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          this['couleur_' + (j + arr[4])].pos_x = this.percentX( col + (lg * 5));
          this['couleur_' + (j + arr[4])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          this['couleur_' + (j + arr[5])].pos_x = this.percentX( col + (lg * 6));
          this['couleur_' + (j + arr[5])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          this['couleur_' + (j + arr[6])].pos_x = this.percentX( col + (lg * 7));
          this['couleur_' + (j + arr[6])].pos_y = this.percentY(- this.tapis.height / 20 + pos[k] * element_mg_top + pos[k] * element_height);

          document.getElementById('couleur_' + (j + arr[0])).style.zIndex = "500";
          document.getElementById('couleur_' + (j + arr[1])).style.zIndex = "501";
          document.getElementById('couleur_' + (j + arr[2])).style.zIndex = "502";
          document.getElementById('couleur_' + (j + arr[3])).style.zIndex = "503";
          document.getElementById('couleur_' + (j + arr[4])).style.zIndex = "504";
          document.getElementById('couleur_' + (j + arr[5])).style.zIndex = "505";
          document.getElementById('couleur_' + (j + arr[6])).style.zIndex = "506";

          k = k + 1;
        }

      }

    },
    getTapisPosition() {
      var tapis_element = document.getElementById('img_tapis');
      const rect        = tapis_element.getBoundingClientRect();
      this.tapis.top    = rect.top;
      this.tapis.right  = rect.right;
      this.tapis.left   = rect.left;
      this.tapis.width  = rect.width;
      this.tapis.bottom = rect.bottom;
      this.tapis.height = rect.height;

      tapis_element     = null;
    },
    onDown(e) {
      var element    = e.currentTarget;
      this.element.clicked = element.id;
      element     = null;
    },
    moveAt() {
      var element_id = this.element.clicked;
      if (element_id && this.element.clicked !== false) {
        var element     = document.getElementById(element_id).getBoundingClientRect();
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.width/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.height/2));

        if ((this[element_id].pos_x * this.tapis.width) < 0) {
          this[element_id].pos_x = 0 ;
        }
        if ((this[element_id].pos_y * this.tapis.height) < 0) {
          this[element_id].pos_y = 0 ;
        }

        if ((this[element_id].pos_x * this.tapis.width) + element.width  > this.tapis.width) {
          this[element_id].pos_x = this.percentX(this.tapis.width -  element.width);
        }

        if ((this[element_id].pos_y * this.tapis.height) + element.height > this.tapis.height) {
          this[element_id].pos_y = this.percentY(this.tapis.height - element.height);
        }
        element     = null;
        element_id  = null;
      }
    },
    onUp(e) {
      // e.preventDefault();
      var element    = e.currentTarget;
      var element_id = element.id;
      this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.offsetWidth/2));
      this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.offsetHeight/2));
      this.element.clicked = false;
      element     = null;
      element_id  = null;
    },
    onMouseMove(e) {
      // e.preventDefault();
        var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
        var element    = e.currentTarget;
        this.mouse_position.pox_x = evt.pageX;
        this.mouse_position.pox_y = evt.pageY;
        if (element !== 'undefined') {
          this.moveAt();
        }
        evt     = null;
        element  = null;
    },
    percentX(x){
      return Math.round(1000 * x/this.tapis.width)/1000;
    },
    percentY(y){
      return Math.round(10000 * y/this.tapis.height)/10000;
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
        }

        return array;
      }
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .container{
    width: calc(100% - 40px);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .tapis{
    display: block;
    position: relative;
    overflow: hidden;
  }
  .tapis img:first-child {
    margin: 0;
    width: 100%;
    height: auto;
    pointer-events: none;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .couleur_1{
    display: block;
    width: 9%;
    height: auto;
    position: absolute;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    margin: 20px;
  }
  .btn:hover {
    background-color: white; /* Green */
    color: #000;
    border: solid 1px #4CAF50;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

  </style>
