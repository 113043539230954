<template>
  <div class="box_title">
    <h1>Le cabinet botanique n°3</h1>
  </div>
  <div class="container">

    <button @click="shuffleAll" class="btn" type="button" name="button">Ranger</button>

    <div id="tapis" class="tapis">
      <!-- Carpet  -->
      <img src='../../assets/images/fonds/fond-botanique.png' id="img_tapis" class="img_tapis" draggable="false"
      @mousemove.left="onMouseMove"
      @touchmove="onMouseMove"
      >
      <!-- Elements  -->
      <img v-for="index in 10"  :key="index" :id="'poly_' + [index]" class="poly_1"
      @click="onMouseMove"
      @mousemove.left="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup.left="onUp"
      @mousedown.left="onDown"
      draggable="false"
      :src="require('../../assets/images/botanique-3/' + this['poly_' + index].link + '.png')"
      :style="{ left: this['poly_' + index].pos_x * this.tapis.width + 'px', top: this['poly_' + index].pos_y * this.tapis.height + 'px' }">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Botanique3',
  mounted() {

    const io = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.getTapisPosition()
      })
    })
    const tapis_element = document.getElementById('tapis');
    io.observe(tapis_element);

    window.addEventListener('load', () => {
      setTimeout( () => this.initPositions(), 300);
    })

  },
  data() {
    return {
      tapis : {
        top    : 0,
        right  : 0,
        bottom : 0,
        left   : 0,
        height : 0,
        width  : 0,
      },
      mouse_position : {
        pos_x : 0,
        pos_y : 0
      },
      poly_1: {
        link : 'poly-1',
        pos_x: 0,
        pos_y: 0
      },
      poly_2: {
        link : 'poly-2',
        pos_x: 0,
        pos_y: 0
      },
      poly_3: {
        link : 'poly-3',
        pos_x: 0,
        pos_y: 0
      },
      poly_4: {
        link : 'poly-4',
        pos_x: 0,
        pos_y: 0
      },
      poly_5: {
        link : 'poly-5',
        pos_x: 0,
        pos_y: 0
      },
      poly_6: {
        link : 'poly-6',
        pos_x: 0,
        pos_y: 0
      },
      poly_7: {
        link : 'poly-7',
        pos_x: 0,
        pos_y: 0
      },

      poly_8: {
        link : 'poly-8',
        pos_x: 0,
        pos_y: 0
      },
      poly_9: {
        link : 'poly-9',
        pos_x: 0,
        pos_y: 0
      },
      poly_10: {
        link : 'poly-10',
        pos_x: 0,
        pos_y: 0
      },
      element : {
        clicked : null,
      },
      shuffle_values : {
        value : null,
      }
    }
  },
  methods: {
    initPositions(){

      for (var j = 1; j < 12; j++) {

        if ( j % 6 == 12 || j == 1 ) {
          var arr = j == 1 ? [0,1,2,3,4,5,6,7,8,9,10,11] : [1,2,3,4,5,6,7,8,9,10,11,12];

          var lf_1 = 0.436 * this.tapis.width/3;
          var lf_2 = 1.134 * this.tapis.width/3;
          var lf_3 = 1.83 * this.tapis.width/3;


          var raw_1 = 0.188 * this.tapis.height/2;
          var raw_2 = 0.975 * this.tapis.height/2;

          var col = 0;
          var pos = [1,2,3,4,5,6,7,8,9,10];

          this['poly_' + (j + arr[0])].pos_x = this.percentX(lf_1);
          this['poly_' + (j + arr[0])].pos_y = this.percentY(raw_1);

          this['poly_' + (j + arr[1])].pos_x = this.percentX(lf_2 + 0.19 * this.tapis.width/3);
          this['poly_' + (j + arr[1])].pos_y = this.percentY(raw_1 + 0.19 * this.tapis.height/2);


          this['poly_' + (j + arr[2])].pos_x = this.percentX(lf_2);
          this['poly_' + (j + arr[2])].pos_y = this.percentY(raw_1);

          this['poly_' + (j + arr[3])].pos_x = this.percentX(lf_3 + 0.235 * this.tapis.width/3);
          this['poly_' + (j + arr[3])].pos_y = this.percentY(raw_1 + 0.196 * this.tapis.height/2);

          this['poly_' + (j + arr[4])].pos_x = this.percentX(lf_3);
          this['poly_' + (j + arr[4])].pos_y = this.percentY(raw_1);

          this['poly_' + (j + arr[5])].pos_x = this.percentX(lf_1 + 0.292 * this.tapis.width/3);
          this['poly_' + (j + arr[5])].pos_y = this.percentY(raw_1 + 0.2 * this.tapis.height/2);

          this['poly_' + (j + arr[6])].pos_x = this.percentX(lf_1 + 0.25 * this.tapis.width/3);
          this['poly_' + (j + arr[6])].pos_y = this.percentY(raw_2 + 0.195 * this.tapis.height/2);

          this['poly_' + (j + arr[7])].pos_x = this.percentX(lf_1);
          this['poly_' + (j + arr[7])].pos_y = this.percentY(raw_2);

          this['poly_' + (j + arr[8])].pos_x = this.percentX(lf_3 + 0.29 * this.tapis.width/3);
          this['poly_' + (j + arr[8])].pos_y = this.percentY(raw_2 + 0.167 * this.tapis.height/2);

          this['poly_' + (j + arr[9])].pos_x = this.percentX(lf_3);
          this['poly_' + (j + arr[9])].pos_y = this.percentY(raw_2);

          document.getElementById('poly_' + 1).style.zIndex = '100';
          document.getElementById('poly_' + 2).style.zIndex = '110';
          document.getElementById('poly_' + 3).style.zIndex = '100';
          document.getElementById('poly_' + 4).style.zIndex = '110';
          document.getElementById('poly_' + 5).style.zIndex = '100';
          document.getElementById('poly_' + 6).style.zIndex = '110';
          document.getElementById('poly_' + 7).style.zIndex = '110';
          document.getElementById('poly_' + 8).style.zIndex = '100';
          document.getElementById('poly_' + 9).style.zIndex = '110';
          document.getElementById('poly_' + 10).style.zIndex = '100';
        }
      }
    },
    getTapisPosition() {
      var tapis_element = document.getElementById('img_tapis');
      const rect        = tapis_element.getBoundingClientRect();
      this.tapis.top    = rect.top;
      this.tapis.right  = rect.right;
      this.tapis.left   = rect.left;
      this.tapis.width  = rect.width;
      this.tapis.bottom = rect.bottom;
      this.tapis.height = rect.height;
    },
    onDown(e) {
      var element    = e.currentTarget;
      this.element.clicked = element.id;
    },
    moveAt() {
      var element_id = this.element.clicked;
      if (element_id && this.element.clicked !== null) {
        var element     = document.getElementById(element_id).getBoundingClientRect();
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.width/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.height/2));

        if ((this[element_id].pos_x * this.tapis.width) < 0) {
          this[element_id].pos_x = 0 ;
        }
        if ((this[element_id].pos_y * this.tapis.height) < 0) {
          this[element_id].pos_y = 0 ;
        }

        if ((this[element_id].pos_x * this.tapis.width) + element.width  > this.tapis.width) {
          this[element_id].pos_x = this.percentX(this.tapis.width -  element.width);
        }

        if ((this[element_id].pos_y * this.tapis.height) + element.height > this.tapis.height) {
          this[element_id].pos_y = this.percentY(this.tapis.height - element.height);
        }
      }
    },
    onUp(e) {
      // e.preventDefault();
      var element    = e.currentTarget;
      var element_id = element.id;
      this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.offsetWidth/2) + 8);
      this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.offsetHeight/2) + 8);
      this.element.clicked = null;

    },
    onMouseMove(e) {
      e.preventDefault();
        var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
        var element    = e.currentTarget;
        this.mouse_position.pox_x = evt.pageX;
        this.mouse_position.pox_y = evt.pageY;
        if (element !== 'undefined') {
          this.moveAt();
        }
    },
    percentX(x){
      return Math.round(1000 * x/this.tapis.width)/1000;
    },
    percentY(y){
      return Math.round(10000 * y/this.tapis.height)/10000;
    },
    shuffleAll(){
      this.shuffle_values.value = true;
      this.initPositions();

      setTimeout( () => {
        this.shuffle_values.value = null;
      }, 300);
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
        }

        return array;
      }
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .container{
    width: calc(100% - 40px);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .tapis{
    display: block;
    position: relative;
    overflow: hidden;
  }
  .tapis img:first-child {
    margin: 0;
    width: 100%;
    height: auto;
    /* pointer-events: none; */
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .poly_1{
    display: block;
    /* width: 22.851%; */
    /* height: 20%; */
    /* width: auto; */
    height: auto;
    position: absolute;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

   -webkit-transform: scale(0.95); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.95); /* FF3.5+ */
     -ms-transform: scale(0.95); /* IE9 */
      -o-transform: scale(0.95); /* Opera 10.95+ */
         transform: scale(0.95);
            /* IE6–IE9 */
            filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand');
  }
  .btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    margin: 20px;
  }
  .btn:hover {
    background-color: white; /* Green */
    color: #000;
    border: solid 1px #4CAF50;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

  #poly_1{
    height: 41%;
  }
  #poly_2{
    height: 22%;
  }
  #poly_3{
    height: 41%;
  }
  #poly_4{
    height: 21.5%;
  }
  #poly_5{
    height: 41%;
  }
  #poly_6{
    height: 21%;
  }
  #poly_7{
    height: 22%;
  }
  #poly_8{
    height: 41%;
  }
  #poly_9{
    height: 25%;
  }
  #poly_10{
    height: 41%;
  }
  #poly_11{
    height: 22%;
  }
  #poly_12{
    height: 41%;
  }

  </style>
