<template>
  <CubeBinome/>
</template>

<script>
// @ is an alias to /src
import CubeBinome from '@/components/le-cube-du-binome/CubeBinome.vue'

export default {
  name: 'CubeBinomePage',
  components: {
    CubeBinome
  }
}
</script>
