<template>
  <EscalierMarron/>
</template>

<script>
// @ is an alias to /src
import EscalierMarron from '@/components/l-escalier-marron/EscalierMarron.vue'

export default {
  name: 'EscalierMarronPage',
  components: {
    EscalierMarron
  }
}
</script>
