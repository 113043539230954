<template>
  <Botanique3/>
</template>

<script>
// @ is an alias to /src
import Botanique3 from '@/components/botanique-3/Botanique3.vue'

export default {
  name: 'Botanique3Page',
  components: {
    Botanique3
  }
}
</script>
