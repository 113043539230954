<template>
  <Clochettes/>
</template>

<script>
// @ is an alias to /src
import Clochettes from '@/components/clochettes/Clochettes.vue'

export default {
  name: 'ClochettesPage',
  components: {
    Clochettes
  }
}
</script>
