<template>
  <div class="box_title">
    <h1>La 2ème boîte des couleurs</h1>
  </div>
  <div class="container">
    <button @click="initPositions" class="btn" type="button" name="button">Ranger</button>
    <div id="tapis" class="tapis">
      <!-- Carpet  -->
      <img src='../../assets/images/fonds/fond.png' id="img_tapis" class="img_tapis" draggable="false"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      >
      <!-- Elements  -->
      <img id="couleur_1" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/blanc.png"
      :style="{ left: this.couleur_1.pos_x * this.tapis.width + 'px', top: this.couleur_1.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_2" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/bleu.png"
      :style="{ left: this.couleur_2.pos_x * this.tapis.width + 'px', top: this.couleur_2.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_3" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/gris.png"
      :style="{ left: this.couleur_3.pos_x * this.tapis.width + 'px', top: this.couleur_3.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_4" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/jaune.png"
      :style="{ left: this.couleur_4.pos_x * this.tapis.width + 'px', top: this.couleur_4.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_5" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/marron.png"
      :style="{ left: this.couleur_5.pos_x * this.tapis.width + 'px', top: this.couleur_5.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_6" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/noir.png"
      :style="{ left: this.couleur_6.pos_x * this.tapis.width + 'px', top: this.couleur_6.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_7" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/orange.png"
      :style="{ left: this.couleur_7.pos_x * this.tapis.width + 'px', top: this.couleur_7.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_8" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/rose.png"
      :style="{ left: this.couleur_8.pos_x * this.tapis.width + 'px', top: this.couleur_8.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_9" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/rouge.png"
      :style="{ left: this.couleur_9.pos_x * this.tapis.width + 'px', top: this.couleur_9.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_10" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/vert.png"
      :style="{ left: this.couleur_10.pos_x * this.tapis.width + 'px', top: this.couleur_10.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_11" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/violet.png"
      :style="{ left: this.couleur_11.pos_x * this.tapis.width + 'px', top: this.couleur_11.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_12" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/blanc.png"
      :style="{ left: this.couleur_12.pos_x * this.tapis.width + 'px', top: this.couleur_12.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_13" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/bleu.png"
      :style="{ left: this.couleur_13.pos_x * this.tapis.width + 'px', top: this.couleur_13.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_14" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/gris.png"
      :style="{ left: this.couleur_14.pos_x * this.tapis.width + 'px', top: this.couleur_14.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_15" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/jaune.png"
      :style="{ left: this.couleur_15.pos_x * this.tapis.width + 'px', top: this.couleur_15.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_16" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/marron.png"
      :style="{ left: this.couleur_16.pos_x * this.tapis.width + 'px', top: this.couleur_16.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_17" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/noir.png"
      :style="{ left: this.couleur_17.pos_x * this.tapis.width + 'px', top: this.couleur_17.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_18" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/orange.png"
      :style="{ left: this.couleur_18.pos_x * this.tapis.width + 'px', top: this.couleur_18.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_19" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/rose.png"
      :style="{ left: this.couleur_19.pos_x * this.tapis.width + 'px', top: this.couleur_19.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_20" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/rouge.png"
      :style="{ left: this.couleur_20.pos_x * this.tapis.width + 'px', top: this.couleur_20.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_21" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/vert.png"
      :style="{ left: this.couleur_21.pos_x * this.tapis.width + 'px', top: this.couleur_21.pos_y * this.tapis.height + 'px' }">

      <img id="couleur_22" class="couleur_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      src="../../assets/images/boite-des-couleurs-2/violet.png"
      :style="{ left: this.couleur_22.pos_x * this.tapis.width + 'px', top: this.couleur_22.pos_y * this.tapis.height + 'px' }">

    </div>
  </div>
</template>

<script>
export default {
  name: 'BoiteDesCouleurs2',
  mounted() {

    const io = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.getTapisPosition()
      })
    })
    const tapis_element = document.getElementById('tapis');
    io.observe(tapis_element);

    window.addEventListener('load', () => {
      setTimeout( () => this.initPositions(), 300);
    })

  },
  data() {
    return {
      tapis : {
        top    : 0,
        right  : 0,
        bottom : 0,
        left   : 0,
        height : 0,
        width  : 0,
      },
      mouse_position : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_1 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_2 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_3 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_4 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_5 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_6 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_7 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_8 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_9 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_10 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_11 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_12 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_13 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_14 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_15 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_16 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_17 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_18 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_19 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_20 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_21 : {
        pos_x : 0,
        pos_y : 0
      },
      couleur_22 : {
        pos_x : 0,
        pos_y : 0
      },
      element : {
        clicked : null,
      }
    }
  },
  methods: {
    initPositions(){
      var arr = Array.from({length: 22}, (_, i) => i + 1);

      this.shuffle(arr);

      var element_width = document.getElementById('couleur_1').getBoundingClientRect().width;
      var element_mg_left = document.getElementById('couleur_1').getBoundingClientRect().width / 10;

      var element_height = document.getElementById('couleur_1').getBoundingClientRect().height;
      var element_mg_top = document.getElementById('couleur_1').getBoundingClientRect().height / 5;

      console.log('element_height ==> ' + element_height);

      this['couleur_' + arr[0]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[0]].pos_y = this.percentY(element_mg_top);

      this['couleur_' + arr[1]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[1]].pos_y = this.percentY(2 * element_mg_top + element_height);

      this['couleur_' + arr[2]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[2]].pos_y = this.percentY(3 * element_mg_top + 2 * element_height);

      this['couleur_' + arr[3]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[3]].pos_y = this.percentY(4 * element_mg_top + 3 * element_height);

      this['couleur_' + arr[4]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[4]].pos_y = this.percentY(5 * element_mg_top + 4 * element_height);

      this['couleur_' + arr[5]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[5]].pos_y = this.percentY(6 * element_mg_top + 5 * element_height);

      this['couleur_' + arr[6]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[6]].pos_y = this.percentY(7 * element_mg_top + 6 * element_height);

      this['couleur_' + arr[7]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[7]].pos_y = this.percentY(8 * element_mg_top + 7 * element_height);

      this['couleur_' + arr[8]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[8]].pos_y = this.percentY(9 * element_mg_top + 8 * element_height);

      this['couleur_' + arr[9]].pos_x = this.percentX(element_mg_left);
      this['couleur_' + arr[9]].pos_y = this.percentY(10 * element_mg_top + 9 * element_height);

      this['couleur_' + arr[10]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[10]].pos_y = this.percentY(element_mg_top + 0 * element_height);

      this['couleur_' + arr[11]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[11]].pos_y = this.percentY(2 * element_mg_top + 1 * element_height);

      this['couleur_' + arr[12]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[12]].pos_y = this.percentY(3 * element_mg_top + 2 * element_height);

      this['couleur_' + arr[13]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[13]].pos_y = this.percentY(4 * element_mg_top + 3 * element_height);

      this['couleur_' + arr[14]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[14]].pos_y = this.percentY(5 * element_mg_top + 4 * element_height);

      this['couleur_' + arr[15]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[15]].pos_y = this.percentY(6 * element_mg_top + 5 * element_height);

      this['couleur_' + arr[16]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[16]].pos_y = this.percentY(7 * element_mg_top + 6 * element_height);

      this['couleur_' + arr[17]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[17]].pos_y = this.percentY(8 * element_mg_top + 7 * element_height);

      this['couleur_' + arr[18]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[18]].pos_y = this.percentY(9 * element_mg_top + 8 * element_height);

      this['couleur_' + arr[19]].pos_x = this.percentX(2 * element_mg_left + element_width);
      this['couleur_' + arr[19]].pos_y = this.percentY(10 * element_mg_top + 9 * element_height);

      this['couleur_' + arr[20]].pos_x = this.percentX(3 * element_mg_left + 2 * element_width);
      this['couleur_' + arr[20]].pos_y = this.percentY(element_mg_top + 0 * element_height);

      this['couleur_' + arr[21]].pos_x = this.percentX(3 * element_mg_left + 2 * element_width);
      this['couleur_' + arr[21]].pos_y = this.percentY(2 * element_mg_top + 1 * element_height);

    },
    getTapisPosition() {
      var tapis_element = document.getElementById('img_tapis');
      const rect        = tapis_element.getBoundingClientRect();
      this.tapis.top    = rect.top;
      this.tapis.right  = rect.right;
      this.tapis.left   = rect.left;
      this.tapis.width  = rect.width;
      this.tapis.bottom = rect.bottom;
      this.tapis.height = rect.height;
    },
    onDown(e) {
      var element    = e.currentTarget;
      this.element.clicked = element.id;
    },
    moveAt() {
      var element_id = this.element.clicked;
      if (element_id && this.element.clicked !== false) {
        var element     = document.getElementById(element_id).getBoundingClientRect();
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.width/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.height/2));

        if ((this[element_id].pos_x * this.tapis.width) < 0) {
          this[element_id].pos_x = 0 ;
        }
        if ((this[element_id].pos_y * this.tapis.height) < 0) {
          this[element_id].pos_y = 0 ;
        }

        if ((this[element_id].pos_x * this.tapis.width) + element.width  > this.tapis.width) {
          this[element_id].pos_x = this.percentX(this.tapis.width -  element.width);
        }

        if ((this[element_id].pos_y * this.tapis.height) + element.height > this.tapis.height) {
          this[element_id].pos_y = this.percentY(this.tapis.height - element.height);
        }
      }
    },
    onUp(e) {
      // e.preventDefault();
      var element    = e.currentTarget;
      var element_id = element.id;
      this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.offsetWidth/2));
      this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.offsetHeight/2));
      this.element.clicked = false;

    },
    onMouseMove(e) {
      e.preventDefault();
        var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
        var element    = e.currentTarget;
        this.mouse_position.pox_x = evt.pageX;
        this.mouse_position.pox_y = evt.pageY;
        if (element !== 'undefined') {
          this.moveAt();
        }
    },
    percentX(x){
      return Math.round(1000 * x/this.tapis.width)/1000;
    },
    percentY(y){
      return Math.round(10000 * y/this.tapis.height)/10000;
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
        }

        return array;
      }
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .container{
    width: calc(100% - 40px);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .tapis{
    display: block;
    position: relative;
    overflow: hidden;
  }
  .tapis img:first-child {
    margin: 0;
    width: 100%;
    height: auto;
    /* pointer-events: none; */
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .couleur_1{
    display: block;
    width: 9%;
    height: auto;
    position: absolute;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    margin: 20px;
  }
  .btn:hover {
    background-color: white; /* Green */
    color: #000;
    border: solid 1px #4CAF50;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

  </style>
