<template>
  <Botanique2/>
</template>

<script>
// @ is an alias to /src
import Botanique2 from '@/components/botanique-2/Botanique2.vue'

export default {
  name: 'Botanique2Page',
  components: {
    Botanique2
  }
}
</script>
