<template>
  <div class="">
    <div class="box_title">
      <h1>Addition Statique - Faire la somme suivante:<br><br>
        <span>
          <span class="number_card" style='color: green'>{{ ''+this.pad(nombre_1, 4)[0] }}</span>
          <span class="number_card" style='color: red'>{{ ''+this.pad(nombre_1, 4)[1] }}</span>
          <span class="number_card" style='color: blue'>{{ ''+this.pad(nombre_1, 4)[2] }}</span>
          <span class="number_card" style='color: green'>{{ ''+this.pad(nombre_1, 4)[3] }}</span>
        </span>
        <span style='color: red; font-size:30px;margin:20px;'>+</span>
        <span>
          <span class="number_card" style='color: green'>{{ ''+this.pad(nombre_2, 4)[0] }}</span>
          <span class="number_card" style='color: red'>{{ ''+this.pad(nombre_2, 4)[1] }}</span>
          <span class="number_card" style='color: blue'>{{ ''+this.pad(nombre_2, 4)[2] }}</span>
          <span class="number_card" style='color: green'>{{ ''+this.pad(nombre_2, 4)[3] }}</span>
        </span>

      </h1>
    </div>
  </div>
  <div class="container">
    <div class="box_numeration" v-if="!plateaux_ok">
      <div class="numeration">
        <img id="millier" draggable="true" @dragstart="startDrag('millier', null)" class="img_numeration_millier" src="../assets/images/systeme-decimal/materiel/png/millier.png">
      </div>
      <div class="numeration">
        <img id="centaine" draggable="true" @dragstart="startDrag('centaine', null)" class="img_numeration_centaine" src="../assets/images/systeme-decimal/materiel/png/centaine.png">
      </div>
      <div class="numeration">
        <img id="dizaine" draggable="true" @dragstart="startDrag('dizaine', null)" class="img_numeration_dizaine" src="../assets/images/systeme-decimal/materiel/png/dizaine.png">
      </div>
      <div class="numeration">
        <img id="unite" draggable="true" @dragstart="startDrag('unite', null)" class="img_numeration_unite" src="../assets/images/systeme-decimal/materiel/png/unite.png">
      </div>
    </div>
    <div class="mt_50" v-if="plateaux_ok">
    </div>

    <div class="row"  id='check_ok'>
      <div class="column">
        <div class="" v-if="!plateaux_ok">
          <span class="plateau_1_2">
            <span>
              <span class="number_card" style='color: green'>{{ ''+this.pad(this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine * 10 + this.plateau_1.unite, 4)[0] }}</span>
              <span class="number_card" style='color: red'>{{ ''+this.pad(this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine * 10 + this.plateau_1.unite, 4)[1] }}</span>
              <span class="number_card" style='color: blue'>{{ ''+this.pad(this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine * 10 + this.plateau_1.unite, 4)[2] }}</span>
              <span class="number_card" style='color: green'>{{ ''+this.pad(this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine * 10 + this.plateau_1.unite, 4)[3] }}</span>
            </span>
          </span>
        </div>

        <div class="plateau hover_plateau mt_30" @drop="onDrop('plateau_1')" @drop.stop.prevent @dragover.prevent @dragenter.prevent>
          <div  class="contenu_plateau_container">
          </div>
          <div class="row contenu_plateau">
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_1.millier)" :key="n" src="../assets/images/systeme-decimal/materiel/png/millier.png" style="max-width:40%;"  v-on:dblclick="decrement('millier', 'plateau_1')" draggable="true" @dragstart="startDrag('millier', 'plateau_1')">
            </div>
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_1.centaine)" :key="n" src="../assets/images/systeme-decimal/materiel/png/centaine.png" style="max-width:40%;" v-on:dblclick="decrement('centaine', 'plateau_1')" draggable="true" @dragstart="startDrag('centaine', 'plateau_1')">
            </div>
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_1.dizaine)" :key="n" src="../assets/images/systeme-decimal/materiel/png/dizaine.png" style="max-width:30%;" v-on:dblclick="decrement('dizaine', 'plateau_1')" draggable="true" @dragstart="startDrag('dizaine', 'plateau_1')">
            </div>
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_1.unite)" :key="n" src="../assets/images/systeme-decimal/materiel/png/unite.png" style="max-width:8%;margin:3px;" v-on:dblclick="decrement('unite', 'plateau_1')" draggable="true" @dragstart="startDrag('unite', 'plateau_1')">
            </div>
          </div>
        </div>

      </div>
      <div class="column">
        <div class="" v-if="!plateaux_ok">
          <span class="plateau_1_2">
            <span>
              <span class="number_card" style='color: green'>{{ ''+this.pad(this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine * 10 + this.plateau_2.unite, 4)[0] }}</span>
              <span class="number_card" style='color: red'>{{ ''+this.pad(this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine * 10 + this.plateau_2.unite, 4)[1] }}</span>
              <span class="number_card" style='color: blue'>{{ ''+this.pad(this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine * 10 + this.plateau_2.unite, 4)[2] }}</span>
              <span class="number_card" style='color: green'>{{ ''+this.pad(this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine * 10 + this.plateau_2.unite, 4)[3] }}</span>
            </span>
          </span>
        </div>
        <div class="plateau hover_plateau mt_30" @drop="onDrop('plateau_2')" @drop.stop.prevent @dragover.prevent @dragenter.prevent>
          <div  class="contenu_plateau_container">
          </div>
          <div class="row contenu_plateau">
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_2.millier)" :key="n" src="../assets/images/systeme-decimal/materiel/png/millier.png" style="max-width:40%;"  v-on:dblclick="decrement('unite', 'plateau_2')"  draggable="true" @dragstart="startDrag('millier', 'plateau_2')">
            </div>
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_2.centaine)" :key="n" src="../assets/images/systeme-decimal/materiel/png/centaine.png" style="max-width:40%;"  v-on:dblclick="decrement('centaine', 'plateau_2')" draggable="true" @dragstart="startDrag('centaine', 'plateau_2')">
            </div>
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_2.dizaine)" :key="n" src="../assets/images/systeme-decimal/materiel/png/dizaine.png" style="max-width:30%;"  v-on:dblclick="decrement('dizaine', 'plateau_2')" draggable="true" @dragstart="startDrag('dizaine', 'plateau_2')">
            </div>
            <div class="column_plateau">
              <img v-for="n in parseInt(this.plateau_2.unite)" :key="n" src="../assets/images/systeme-decimal/materiel/png/unite.png" style="max-width:8%;margin:3px;"  v-on:dblclick="decrement('unite', 'plateau_2')" draggable="true" @dragstart="startDrag('unite', 'plateau_2')">
            </div>
          </div>
        </div>

      </div>
    </div>


    <div v-if="plateaux_ok">
      <div class="change_box">
        <!-- <h1>La Banque des changes</h1> -->
        <button class="button" v-on:click="showChange()" v-if="this.plateau_3.unite > 9 || this.plateau_3.dizaine > 9 || this.plateau_3.centaine > 9">
          Faire un change
        </button>
        <div v-show="showSection">
          <div class="wrapper box_effect">
            <div class="box change_box">
              <div class="content">
                <div class="numeration">
                  <div class="img_numeration_millier">
                    <img class="" v-for="n in 10" :key="n"  src="../assets/images/systeme-decimal/materiel/png/unite.png" style="max-width:15px; margin:3px;">
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="content">
                <div class="numeration_contre">
                  <div class="img_numeration_millier">
                    <p><b>contre</b></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="content">
                <div class="numeration">
                  <img class="img_numeration_dizaine" src="../assets/images/systeme-decimal/materiel/png/dizaine.png" style="max-width:70px;">
                </div>
              </div>
            </div>
            <button class="button" v-on:click="Change('unite')">
              Sélectionner
            </button>
          </div>

          <div class="wrapper box_effect">
            <div class="box change_box">
              <div class="content">
                <div class="numeration">
                  <div class="img_numeration_millier">
                    <img class="" v-for="n in 10" :key="n"  src="../assets/images/systeme-decimal/materiel/png/dizaine.png" style="max-width:50px; margin:2px;">
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="content">
                <div class="numeration_contre">
                  <div class="img_numeration_millier">
                    <p><b>contre</b></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="content">
                <div class="numeration">
                  <img class="img_numeration_dizaine" src="../assets/images/systeme-decimal/materiel/png/centaine.png" style="max-width:180px;">
                </div>
              </div>
            </div>
            <button class="button" v-on:click="Change('dizaine')">
              Sélectionner
            </button>
          </div>

          <div class="wrapper box_effect">
            <div class="box change_box">
              <div class="content">
                <div class="numeration">
                  <div class="img_numeration_millier">
                    <img class="" v-for="n in 10" :key="n"  src="../assets/images/systeme-decimal/materiel/png/centaine.png" style="max-width:80px; margin:3px;">
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="content">
                <div class="numeration_contre">
                  <div class="img_numeration_millier">
                    <p><b>contre</b></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="content">
                <div class="numeration">
                  <img class="img_numeration_dizaine" src="../assets/images/systeme-decimal/materiel/png/millier.png" style="max-width:180px;">
                </div>
              </div>
            </div>
            <button class="button" v-on:click="Change('centaine')">
              Sélectionner
            </button>
          </div>
        </div>

      </div>
      <div class="">
        <div v-if="this.plateau_3.unite + this.plateau_3.dizaine + this.plateau_3.centaine + this.plateau_3.millier < 1">
          <h2>Additionner, c'est <b>mettre emsemble</b>.</h2> <p>Rassemble les deux plateaux pour connaitre le résultat de l'addition.</p>
        </div>
        <br>
        <span class="plateau_1_2 mt_30">
          <span>
            <span class="number_card" style='color: green'>{{ ''+this.pad(this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine * 10 + this.plateau_3.unite, 4)[0] }}</span>
            <span class="number_card" style='color: red'>{{ ''+this.pad(this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine * 10 + this.plateau_3.unite, 4)[1] }}</span>
            <span class="number_card" style='color: blue'>{{ ''+this.pad(this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine * 10 + this.plateau_3.unite, 4)[2] }}</span>
            <span class="number_card" style='color: green'>{{ ''+this.pad(this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine * 10 + this.plateau_3.unite, 4)[3] }}</span>
          </span>
        </span>
      </div>

      <div class="plateau hover_plateau plateau_resultat_box mt_30" @drop="onDrop('plateau_3')" @drop.stop.prevent @dragover.prevent @dragenter.prevent>
        <div  class="contenu_plateau_container">
        </div>
        <div class="row contenu_plateau">
          <div class="column_plateau">
            <img draggable="false" v-for="n in parseInt(this.plateau_3.millier)" :key="n" src="../assets/images/systeme-decimal/materiel/png/millier.png" style="max-width:40%;"  v-on:dblclick="decrement('unite', 'plateau_3')">
          </div>
          <div class="column_plateau">
            <img draggable="false" v-for="n in parseInt(this.plateau_3.centaine)" :key="n" src="../assets/images/systeme-decimal/materiel/png/centaine.png" style="max-width:40%;"  v-on:dblclick="decrement('centaine', 'plateau_3')">
          </div>
          <div class="column_plateau">
            <img draggable="false" v-for="n in parseInt(this.plateau_3.dizaine)" :key="n" src="../assets/images/systeme-decimal/materiel/png/dizaine.png" style="max-width:25%;"  v-on:dblclick="decrement('dizaine', 'plateau_3')">
          </div>
          <div class="column_plateau">
            <img draggable="false" v-for="n in parseInt(this.plateau_3.unite)" :key="n" src="../assets/images/systeme-decimal/materiel/png/unite.png" style="max-width:5%;margin:3px;"  v-on:dblclick="decrement('unite', 'plateau_3')">
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-default" v-on:click="reInit()">
      RECOMMANCER
    </button>
  </div>
  <Modal v-show="isModalVisible" @close="closeModal" />
</template>


<script>
import Modal from '@/components/Modal.vue'


export default {
  name : 'AdditionStatique',
  components: {
    Modal,
  },
  data() {
    let rand_number = Math.floor(Math.random() * 8000);
    return {
      nombre_1 : rand_number,
      nombre_2 : parseInt(this.check_numbers(this.pad(rand_number, 4))),
      plateau_1 : {
        unite : 0,
        dizaine : 0,
        centaine : 0,
        millier : 0
      },
      plateau_2 : {
        unite: 0,
        dizaine : 0,
        centaine : 0,
        millier : 0
      },
      plateau_3 : {
        unite: 0,
        dizaine : 0,
        centaine : 0,
        millier : 0
      },
      plateaux_ok: false,
      origin : '',
      isModalVisible: false,
      showSection: false,
    }
  },
  methods: {
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    check_numbers(num1) {
      let num = Math.floor(Math.random() * (9999 - parseInt(num1))).toString();
      num.length < 4 ? num = this.pad(num, 4) : num;
      num1.length < 4 ? num1 = this.pad(num1, 4) : num1;
      while (parseInt(num1[3]) + parseInt(num[3]) > 9
      || parseInt(num1[2]) + parseInt(num[2]) > 9
      || parseInt(num1[1]) + parseInt(num[1]) > 9
      || parseInt(num1[0]) + parseInt(num[0]) > 9
      || parseInt(num < 100)){
        num = Math.floor(Math.random() * (9999 - parseInt(num1))).toString();
        num.length < 4 ? num = this.pad(num, 4) : num;
      }
      return parseInt(num);
    },
    increment_p1(choix){
      if(this.plateaux_ok){
        return -1;
      }
      switch (choix) {
        case choix = 'unite':
        this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite < 9999 ? this.plateau_1.unite++ : this.plateau_1.unite;
        break;
        case choix = 'dizaine':
        this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite < 9990 ? this.plateau_1.dizaine++ : this.plateau_1.dizaine;
        break;
        case choix = 'centaine':
        this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite < 9900 ? this.plateau_1.centaine++ : this.plateau_1.centaine;
        break;
        case choix = 'millier':
        this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite < 9000 ? this.plateau_1.millier++ : this.plateau_1.millier;
        break;
        default:
        console.log('erreur plateau 1');
      }
      if (!this.plateaux_ok) {
        this.check_plateaux();
      }
      else{
        this.check_plateau_3();
      }
    },
    increment_p2(choix){
      if(this.plateaux_ok){
        return -1;
      }
      switch (choix) {
        case choix = 'unite':
        this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite < 9999 ? this.plateau_2.unite++ : this.plateau_2.unite;
        break;
        case choix = 'dizaine':
        this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite < 9990 ? this.plateau_2.dizaine++ : this.plateau_2.dizaine;
        break;
        case choix = 'centaine':
        this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite < 9900 ? this.plateau_2.centaine++ : this.plateau_2.centaine;
        break;
        case choix = 'millier':
        this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite < 9000 ? this.plateau_2.millier++ : this.plateau_2.millier;
        break;
        default:
        console.log('erreur plateau 1');
      }
      if (!this.plateaux_ok) {
        this.check_plateaux();
      }
      else{
        this.check_plateau_3();
      }
    },
    increment_p3(choix){
      if(!this.plateaux_ok){
        return -1;
      }
      switch (choix) {
        case choix = 'unite':
        this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite < 9999 ? this.plateau_3.unite++ : this.plateau_3.unite;
        break;
        case choix = 'dizaine':
        this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite < 9990 ? this.plateau_3.dizaine++ : this.plateau_3.dizaine;
        break;
        case choix = 'centaine':
        this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite < 9900 ? this.plateau_3.centaine++ : this.plateau_3.centaine;
        break;
        case choix = 'millier':
        this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite < 9000 ? this.plateau_3.millier++ : this.plateau_3.millier;
        break;
        default:
        console.log('erreur plateau 3');
      }
      if (!this.plateaux_ok) {
        this.check_plateaux();
      }
      else{
        this.check_plateau_3();
      }
    },
    decrement(choix, plateau_choix){
      if(this.plateaux_ok && (this.origin ==! 'plateau_1' || this.origin ==! 'plateau_2')){
        return -1;
      }
      if (plateau_choix == 'plateau_1') {
        switch (choix) {
          case choix = 'unite':
          this.plateau_1.unite > 0 && (this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite > 0) ? this.plateau_1.unite-- : this.plateau_1.unite;
          break;
          case choix = 'dizaine':
          this.plateau_1.dizaine > 0 && (this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite > 0) ? this.plateau_1.dizaine-- : this.plateau_1.dizaine;
          break;
          case choix = 'centaine':
          this.plateau_1.centaine > 0 && (this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite > 0) ? this.plateau_1.centaine-- : this.plateau_1.centaine;
          break;
          case choix = 'millier':
          this.plateau_1.millier > 0 && (this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine *10 + this.plateau_1.unite > 0) ? this.plateau_1.millier-- : this.plateau_1.millier;
          break;
          default:
          console.log('erreur plateau 1');
        }
      }
      else if(plateau_choix == 'plateau_2') {
        switch (choix) {
          case choix = 'unite':
          this.plateau_2.unite > 0 && (this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite > 0) ? this.plateau_2.unite-- : this.plateau_2.unite;
          break;
          case choix = 'dizaine':
          this.plateau_2.dizaine > 0 && (this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite > 0) ? this.plateau_2.dizaine-- : this.plateau_2.dizaine;
          break;
          case choix = 'centaine':
          this.plateau_2.centaine > 0 && (this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite > 0) ? this.plateau_2.centaine-- : this.plateau_2.centaine;
          break;
          case choix = 'millier':
          this.plateau_2.millier > 0 && (this.plateau_2.millier * 1000 + this.plateau_2.centaine * 100 + this.plateau_2.dizaine *10 + this.plateau_2.unite > 0) ? this.plateau_2.millier-- : this.plateau_2.millier;
          break;
          default:
          console.log('erreur plateau 2');
        }
      }
      else if(plateau_choix == 'plateau_3') {
        switch (choix) {
          case choix = 'unite':
          this.plateau_3.unite > 0 && (this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite > 0) ? this.plateau_3.unite-- : this.plateau_3.unite;
          break;
          case choix = 'dizaine':
          this.plateau_3.dizaine > 0 && (this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite > 0) ? this.plateau_3.dizaine-- : this.plateau_3.dizaine;
          break;
          case choix = 'centaine':
          this.plateau_3.centaine > 0 && (this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite > 0) ? this.plateau_3.centaine-- : this.plateau_3.centaine;
          break;
          case choix = 'millier':
          this.plateau_3.millier > 0 && (this.plateau_3.millier * 1000 + this.plateau_3.centaine * 100 + this.plateau_3.dizaine *10 + this.plateau_3.unite > 0) ? this.plateau_3.millier-- : this.plateau_3.millier;
          break;
          default:
          console.log('erreur plateau 3');
        }
      }
      if (!this.plateaux_ok) {
        this.check_plateaux();
      }
      else{
        this.check_plateau_3();
      }
    },
    reInit(){
      this.$router.go();
    }
    ,
    check_plateaux(){
      if(this.plateau_1.millier * 1000 + this.plateau_1.centaine * 100 + this.plateau_1.dizaine * 10 + this.plateau_1.unite == this.nombre_1 && this.plateau_2.millier * 1000 + this.plateau_2.centaine *100 + this.plateau_2.dizaine * 10 + this.plateau_2.unite == this.nombre_2){
        this.plateaux_ok = true;
        setTimeout(function(){
          var check_ok = document.getElementById('check_ok');
          var topPos = check_ok.offsetTop;
          window.scrollTo(0, topPos);
          window.scrollTo({ behavior: 'smooth' });
        }, 100);
        console.log('test OK');
      }
    },
    check_plateau_3(){
      if(''+ this.plateau_3.millier + this.plateau_3.centaine + this.plateau_3.dizaine + this.plateau_3.unite == this.pad(this.nombre_1 + this.nombre_2, 4)){
        console.log('Resultat ====> OK');
        this.showModal();
      }
    },
    startDrag(numeration, plt) {
      if (numeration == 'unite') {
        this.source = 'unite';
      }
      else if (numeration == 'dizaine') {
        this.source = 'dizaine';
      }
      else if (numeration == 'centaine') {
        this.source = 'centaine';
      }
      else if (numeration == 'millier') {
        this.source = 'millier';
      }
      if(plt == 'plateau_1' || plt == 'plateau_2'){
        this.origin = plt;
      }
    },
    check_ordre(){
      let valeur = false;
      if(this.source == 'dizaine'){
        if(this.plateau_1.unite > 0 || this.plateau_2.unite > 0){
          valeur = true;
        }
      }
      else if(this.source == 'centaine') {
        if(this.plateau_1.unite > 0 || this.plateau_2.unite > 0 || this.plateau_1.dizaine > 0 || this.plateau_2.dizaine > 0){
          valeur = true;
        }
      }
      else if(this.source == 'millier') {
        if(this.plateau_1.unite > 0 || this.plateau_2.unite > 0 || this.plateau_1.dizaine > 0 || this.plateau_2.dizaine > 0 || this.plateau_1.centaine > 0 || this.plateau_2.centaine > 0){
          valeur = true;
        }
      }
      return valeur;
    },
    onDrop(plateau) {
      if (plateau === 'plateau_1') {
        this.increment_p1(this.source);
      }
      else if (plateau === 'plateau_2') {
        this.increment_p2(this.source);
      }
      else if (plateau === 'plateau_3' && (this.origin == 'plateau_1' || this.origin == 'plateau_2')) {
        if (!this.check_ordre()) {
          this.increment_p3(this.source);
          if (this.origin == 'plateau_1' || this.origin == 'plateau_2') {
            this.decrement(this.source, this.origin);
            this.origin = '';
          }
        }
        else {
          alert('L\'addition doit se faire dans l\'ordre, unités, dizaines, centaines puis milliers.');
        }
      }


      if (!this.plateaux_ok) {
        this.check_plateaux();
      }
      else{
        this.check_plateau_3();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showChange(){
      this.showSection = !this.showSection
      console.log('toggle');
    }
    ,
    Change(choix){
      switch (choix) {
        case choix = 'unite':
        if(this.plateau_3.unite > 9){
          this.plateau_3.unite = this.plateau_3.unite - 10;
          this.plateau_3.dizaine = this.plateau_3.dizaine + 1;
        }
        else {
          console.log('Pas assez d\'unité pour le change');
        }
        break;
        case choix = 'dizaine':
        if(this.plateau_3.dizaine > 9){
          this.plateau_3.dizaine = this.plateau_3.dizaine - 10;
          this.plateau_3.centaine = this.plateau_3.centaine + 1;
        }
        else {
          console.log('Pas assez de dizaine pour le change');
        }
        break;
        case choix = 'centaine':
        if(this.plateau_3.centaine > 9){
          this.plateau_3.centaine = this.plateau_3.centaine - 10;
          this.plateau_3.millier = this.plateau_3.millier + 1;
        }
        else {
          console.log('Pas assez de centaine pour le change');
        }
        break;
        default:
        console.log('erreur de change plateau 3');
      }
      this.showSection = false;
      this.check_plateau_3();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container{
  width: calc(100% - 40px);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.column {
  float: left;
  width: 50%;
}

.column_plateau {
  float: left;
  width: 25%;
  margin: 3%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.plateau{
  width: 100%;
}

.box_numeration{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  overflow: hidden;
  margin: 0;

}
.numeration {
  height: 200px;
  width: 200px;
  margin: 20px;
  padding: 20px;
  border: solid 3px black;
  background: white;
  position: relative;
}

.numeration_contre{
  height: 200px;
  width: 200px;
  margin: 20px;
  padding: 20px;
  background: white;
  position: relative;
}

.img_numeration_unite{
  width: 10%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img_numeration_dizaine{
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img_numeration_centaine{
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img_numeration_millier{
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.plateau_1_2{
  font-size: 25px;
  text-align: center;
}

.plateau_1_2 span{
  font-size: 40px;
  font-weight: 500;
}

.plateau_resultat_box{
  padding-top: -30px;
}
.mb_30{
  margin-bottom: 30px;
}

.hover_plateau  {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.hover_plateau:hover  {
  -webkit-transform: scale(1.005);
  transform: scale(1.005);
}
.contenu_plateau_container{
  background: url("../assets/images/systeme-decimal/materiel/png/plateau.png") no-repeat;
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-size: 100%;
}
.contenu_plateau{
  position: absolute;
  width: 90%;
  top: 5%;
  left: 5%;
}
.number_card{
  border: solid 1px black;
  padding: 20px;
  font-size: 40px;
}
.mt_30{
  margin-top: 30px;
}
.mt_50{
  margin-top: 50px;
}
.consigne{
  width: 100%;
  position: fixed;
  background-color: white;
  padding: 10px 0;
  z-index: 1000;
}
.consigne{
  top: 60px;
}
.change_box{
  width: 100%;
}


.wrapper {
  margin: 0 auto;
  /* margin: 0 auto; */
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: inherit;
  box-sizing: border-box;
  border: solid 1px black;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}

.wrapper:after {
  clear: both;
}

.wrapper .box {
  margin: 10px auto;
  padding: 0;
  position: relative;
  float: left;
  width: 33.33333%;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  /* border: solid 1px black; */

}
.box_change{
  position: relative;
}

.box_effect {
  transition: box-shadow .3s;
  margin-top: 20px;
  border-radius:10px;
  border: 1px solid #ccc;
  background: #fff;
  float: left;

}
.box_effect:hover {
  box-shadow: 11px 11px 11px rgba(33,33,33,.2);
}

.box .content {
  margin: 10px;
  padding: 10px;
  min-height: 100px;
  box-sizing: border-box;

}
.border_box{
  /* border: solid 1px black; */
}
.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button:hover {
  background-color: #157819; /* Green */
  color: white;
}
</style>
