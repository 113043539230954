<template>
  <AdditionStatique/>
</template>

<script>
// @ is an alias to /src
import AdditionStatique from '@/components/AdditionStatique.vue'

export default {
  name: 'AdditionStatiquePage',
  components: {
    AdditionStatique
  }
}
</script>
