<template>
  <BoiteDesCouleurs2/>
</template>

<script>
// @ is an alias to /src
import BoiteDesCouleurs2 from '@/components/boites-des-couleurs/BoiteDesCouleurs2.vue'

export default {
  name: 'BoiteDesCouleurs2Page',
  components: {
    BoiteDesCouleurs2
  }
}
</script>
