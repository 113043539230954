<template>
  <div class="box_title">
    <h1>Les barres rouges</h1>
  </div>
  <div class="container">
    <button @click="initPositions" class="btn" type="button" name="button">Ranger</button>

    <button @click="shuffleAll" class="btn" type="button" name="button">Mélanger</button>

    <div id="tapis" class="tapis">
      <!-- Carpet  -->
      <img src='../../assets/images/fonds/fond.png' id="img_tapis" class="img_tapis" draggable="false"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      >
      <!-- Elements  -->
      <img v-for="index in 10"  :key="index" :id="'barre_' + [index]" class="barre_1"
      @click="onMouseMove"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseup="onUp"
      @mousedown="onDown"
      draggable="false"
      :src="require('../../assets/images/les-barres-rouges/' + this['barre_' + index].link + '.png')"
      :style="{ left: this['barre_' + index].pos_x * this.tapis.width + 'px', top: this['barre_' + index].pos_y * this.tapis.height + 'px' }">
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarresRouges',
  mounted() {

    const io = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.getTapisPosition()
      })
    })
    const tapis_element = document.getElementById('tapis');
    io.observe(tapis_element);

    window.addEventListener('load', () => {
      setTimeout( () => this.initPositions(), 300);
    })

  },
  data() {
    return {
      tapis : {
        top    : 0,
        right  : 0,
        bottom : 0,
        left   : 0,
        height : 0,
        width  : 0,
      },
      mouse_position : {
        pos_x : 0,
        pos_y : 0
      },
      barre_1: {
        link : 'barre-1',
        pos_x: 0,
        pos_y: 0
      },
      barre_2: {
        link : 'barre-2',
        pos_x: 0,
        pos_y: 0
      },
      barre_3: {
        link : 'barre-3',
        pos_x: 0,
        pos_y: 0
      },
      barre_4: {
        link : 'barre-4',
        pos_x: 0,
        pos_y: 0
      },
      barre_5: {
        link : 'barre-5',
        pos_x: 0,
        pos_y: 0
      },
      barre_6: {
        link : 'barre-6',
        pos_x: 0,
        pos_y: 0
      },
      barre_7: {
        link : 'barre-7',
        pos_x: 0,
        pos_y: 0
      },

      barre_8: {
        link : 'barre-8',
        pos_x: 0,
        pos_y: 0
      },
      barre_9: {
        link : 'barre-9',
        pos_x: 0,
        pos_y: 0
      },
      barre_10: {
        link : 'barre-10',
        pos_x: 0,
        pos_y: 0
      },
      element : {
        clicked : null,
      },
      shuffle_values : {
        value : null,
      }
    }
  },
  methods: {
    initPositions(){
      var element_width = document.getElementById('barre_1').getBoundingClientRect().width;
      var element_mg_left = document.getElementById('barre_1').getBoundingClientRect().width / 3;

      var element_height = document.getElementById('barre_1').getBoundingClientRect().height;
      var element_mg_top = document.getElementById('barre_1').getBoundingClientRect().height / 5;

      var k = 0;

      for (var j = 1; j < 10; j++) {

        if ( j % 10 == 0 || j == 1 ) {
          var arr = j == 1 ? [0,1,2,3,4,5,6,7,8,9] : [1,2,3,4,5,6,7,8,9,10];

          if (this.shuffle_values.value == true) {
            this.shuffle(arr);
          }

          var lg = element_mg_left;
          var col = 0;
          var pos = [1,2,3,4,5,6,7,8,9,10];

          if (k > 2 && k < 6) {
            col = this.tapis.width / 3;
          }
          else if(k >= 6) {
            col = 2 * this.tapis.width / 3;
          }


          this['barre_' + (j + arr[0])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[0])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 1);

          this['barre_' + (j + arr[1])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[1])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 3 );

          this['barre_' + (j + arr[2])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[2])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 5 );

          this['barre_' + (j + arr[3])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[3])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 7 );

          this['barre_' + (j + arr[4])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[4])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height * 9 );

          this['barre_' + (j + arr[5])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[5])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 11 );

          this['barre_' + (j + arr[6])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[6])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 13 );

          this['barre_' + (j + arr[7])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[7])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 15 );

          this['barre_' + (j + arr[8])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[8])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 17 );

          this['barre_' + (j + arr[9])].pos_x = this.percentX( col + (lg * 1));
          this['barre_' + (j + arr[9])].pos_y = this.percentY(pos[k] * element_mg_top + pos[k] * element_height  * 19 );

          document.getElementById('barre_' + (j + arr[0])).style.zIndex = (500 - (j + arr[0])) + '';
          document.getElementById('barre_' + (j + arr[1])).style.zIndex = (500 - (j + arr[1])) + '';
          document.getElementById('barre_' + (j + arr[2])).style.zIndex = (500 - (j + arr[2])) + '';
          document.getElementById('barre_' + (j + arr[3])).style.zIndex = (500 - (j + arr[3])) + '';
          document.getElementById('barre_' + (j + arr[4])).style.zIndex = (500 - (j + arr[4])) + '';
          document.getElementById('barre_' + (j + arr[5])).style.zIndex = (500 - (j + arr[5])) + '';
          document.getElementById('barre_' + (j + arr[6])).style.zIndex = (500 - (j + arr[6])) + '';
          document.getElementById('barre_' + (j + arr[7])).style.zIndex = (500 - (j + arr[7])) + '';
          document.getElementById('barre_' + (j + arr[8])).style.zIndex = (500 - (j + arr[8])) + '';
          document.getElementById('barre_' + (j + arr[9])).style.zIndex = (500 - (j + arr[9])) + '';

          k = k + 1;
        }
      }
    },
    getTapisPosition() {
      var tapis_element = document.getElementById('img_tapis');
      const rect        = tapis_element.getBoundingClientRect();
      this.tapis.top    = rect.top;
      this.tapis.right  = rect.right;
      this.tapis.left   = rect.left;
      this.tapis.width  = rect.width;
      this.tapis.bottom = rect.bottom;
      this.tapis.height = rect.height;
    },
    onDown(e) {
      var element    = e.currentTarget;
      this.element.clicked = element.id;
    },
    moveAt() {
      var element_id = this.element.clicked;
      if (element_id && this.element.clicked !== null) {
        var element     = document.getElementById(element_id).getBoundingClientRect();
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.width/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.height/2));

        if ((this[element_id].pos_x * this.tapis.width) < 0) {
          this[element_id].pos_x = 0 ;
        }
        if ((this[element_id].pos_y * this.tapis.height) < 0) {
          this[element_id].pos_y = 0 ;
        }

        if ((this[element_id].pos_x * this.tapis.width) + element.width  > this.tapis.width) {
          this[element_id].pos_x = this.percentX(this.tapis.width -  element.width);
        }

        if ((this[element_id].pos_y * this.tapis.height) + element.height > this.tapis.height) {
          this[element_id].pos_y = this.percentY(this.tapis.height - element.height);
        }
      }
    },
    onUp(e) {
      // e.preventDefault();
      var element    = e.currentTarget;
      var element_id = element.id;
      this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.offsetWidth/2));
      this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.offsetHeight/2));
      this.element.clicked = null;

    },
    onMouseMove(e) {
      e.preventDefault();
        var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
        var element    = e.currentTarget;
        this.mouse_position.pox_x = evt.pageX;
        this.mouse_position.pox_y = evt.pageY;
        if (element !== 'undefined') {
          this.moveAt();
        }
    },
    percentX(x){
      return Math.round(1000 * x/this.tapis.width)/1000;
    },
    percentY(y){
      return Math.round(10000 * y/this.tapis.height)/10000;
    },
    shuffleAll(){
      this.shuffle_values.value = true;
      this.initPositions();

      setTimeout( () => {
        this.shuffle_values.value = null;
      }, 300);
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
        }

        return array;
      }
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .container{
    width: calc(100% - 40px);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
  .tapis{
    display: block;
    position: relative;
    overflow: hidden;
  }
  .tapis img:first-child {
    margin: 0;
    width: 100%;
    height: auto;
    /* pointer-events: none; */
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .barre_1{
    display: block;
    /* width: 10%; */
    height: 3.5%;
    width: auto;
    /* height: auto; */
    position: absolute;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    margin: 20px;
  }
  .btn:hover {
    background-color: white; /* Green */
    color: #000;
    border: solid 1px #4CAF50;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

  </style>
