<template>
  <TrianglesConstructeurs/>
</template>

<script>
// @ is an alias to /src
import TrianglesConstructeurs from '@/components/les-triangles-constructeurs-1/TrianglesConstructeurs.vue'

export default {
  name: 'TrianglesConstructeursPage',
  components: {
    TrianglesConstructeurs
  }
}
</script>
