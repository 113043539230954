<template>
  <BoiteASon/>
</template>

<script>
// @ is an alias to /src
import BoiteASon from '@/components/la-boite-a-son/BoiteASon.vue'

export default {
  name: 'BoiteASonPage',
  components: {
    BoiteASon
  }
}
</script>
