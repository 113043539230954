<template>
  <BarresRouges/>
</template>

<script>
// @ is an alias to /src
import BarresRouges from '@/components/les-barres-rouges/BarresRouges.vue'

export default {
  name: 'BarresRougesPage',
  components: {
    BarresRouges
  }
}
</script>
