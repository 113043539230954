<template>
  <div class="box_title">
    <h1>Les clochettes</h1>
  </div>
  <div class="container">

    <button @click="initPositions" class="btn" type="button" name="button">Ranger</button>

    <button @click="shuffleAll" class="btn" type="button" name="button">Mélanger</button>

    <div id="tapis" class="tapis">
      <!-- Carpet  -->
      <img src='../../assets/images/fonds/fond.png' id="img_tapis" class="img_tapis" draggable="false"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      >
      <!-- Elements  -->
    <img v-for="index in 16"  :key="index" :id="'clochette_' + [index]" class="clochette_1"
    @click="onMouseMove"
    @dblclick="selectedSound"
    @mousemove="onMouseMove"
    @touchmove="onMouseMove"
    @mouseup="onUp"
    @mousedown="onDown"
    draggable="false"
    :src="require('../../assets/images/clochettes/' + this['clochette_' + index].link + '.png')"
    :style="{ left: this['clochette_' + index].pos_x * this.tapis.width + 'px', top: this['clochette_' + index].pos_y * this.tapis.height + 'px' }">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Clochettes',
  mounted() {
    const io = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.getTapisPosition()
      })
    })
    const tapis_element = document.getElementById('tapis');
    io.observe(tapis_element);

    window.addEventListener('load', () => {
      setTimeout( () => this.initPositions(), 100);
    })

  },
  data() {
    return {
      tapis : {
        top    : 0,
        right  : 0,
        bottom : 0,
        left   : 0,
        height : 0,
        width  : 0,
      },
      mouse_position : {
        pos_x : 0,
        pos_y : 0
      },
      clochette_1: {
        link : 'clochette-blanche',
        sound : 'do',
        pos_x: 0,
        pos_y: 0
      },
      clochette_2: {
        link : 'clochette-blanche',
        sound : 're',
        pos_x: 0,
        pos_y: 0
      },
      clochette_3: {
        link : 'clochette-blanche',
        sound : 'mi',
        pos_x: 0,
        pos_y: 0
      },
      clochette_4: {
        link : 'clochette-blanche',
        sound : 'fa',
        pos_x: 0,
        pos_y: 0
      },
      clochette_5: {
        link : 'clochette-blanche',
        sound : 'sol',
        pos_x: 0,
        pos_y: 0
      },
      clochette_6: {
        link : 'clochette-blanche',
        sound : 'la',
        pos_x: 0,
        pos_y: 0
      },
      clochette_7: {
        link : 'clochette-blanche',
        sound : 'si',
        pos_x: 0,
        pos_y: 0
      },

      clochette_8: {
        link : 'clochette-blanche',
        sound : 'do-2',
        pos_x: 0,
        pos_y: 0
      },
      clochette_9: {
        link : 'clochette-grise',
        sound : 'do',
        pos_x: 0,
        pos_y: 0
      },
      clochette_10: {
        link : 'clochette-grise',
        sound : 're',
        pos_x: 0,
        pos_y: 0
      },
      clochette_11: {
        link : 'clochette-grise',
        sound : 'mi',
        pos_x: 0,
        pos_y: 0
      },
      clochette_12: {
        link : 'clochette-grise',
        sound : 'fa',
        pos_x: 0,
        pos_y: 0
      },
      clochette_13: {
        link : 'clochette-grise',
        sound : 'sol',
        pos_x: 0,
        pos_y: 0
      },
      clochette_14: {
        link : 'clochette-grise',
        sound : 'la',
        pos_x: 0,
        pos_y: 0
      },

      clochette_15: {
        link : 'clochette-grise',
        sound : 'si',
        pos_x: 0,
        pos_y: 0
      },
      clochette_16: {
        link : 'clochette-grise',
        sound : 'do-2',
        pos_x: 0,
        pos_y: 0
      },
      element : {
        clicked : null,
      },
      shuffle_values : {
        value : null,
      }
    }
  },
  methods: {
    initPositions(){

      var element_width = document.getElementById('clochette_1').getBoundingClientRect().width;
      var element_mg_left = document.getElementById('clochette_1').getBoundingClientRect().width / 3;

      var element_height = document.getElementById('clochette_1').getBoundingClientRect().height;
      var element_mg_top = document.getElementById('clochette_1').getBoundingClientRect().height / 5;

      var k = 0;

      for (var j = 1; j < 16; j++) {

        if ( j % 8 == 0 || j == 1 ) {
          var arr = j == 1 ? [0,1,2,3,4,5,6,7] : [1,2,3,4,5,6,7,8];

          if (this.shuffle_values.value == true) {
            this.shuffle(arr);
          }

          var lg = element_width;
          var col = 0;
          var pos = [1,2,3,1,2,3,1,2,3];

          if (k > 2 && k < 6) {
            col = this.tapis.width / 3;
          }
          else if(k >= 6) {
            col = 2 * this.tapis.width / 3;
          }


          this['clochette_' + (j + arr[0])].pos_x = this.percentX( col + (lg * 1));
          this['clochette_' + (j + arr[0])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[1])].pos_x = this.percentX( col + (lg * 2));
          this['clochette_' + (j + arr[1])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[2])].pos_x = this.percentX( col + (lg * 3));
          this['clochette_' + (j + arr[2])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[3])].pos_x = this.percentX( col + (lg * 4));
          this['clochette_' + (j + arr[3])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[4])].pos_x = this.percentX( col + (lg * 5));
          this['clochette_' + (j + arr[4])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[5])].pos_x = this.percentX( col + (lg * 6));
          this['clochette_' + (j + arr[5])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[6])].pos_x = this.percentX( col + (lg * 7));
          this['clochette_' + (j + arr[6])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          this['clochette_' + (j + arr[7])].pos_x = this.percentX( col + (lg * 8));
          this['clochette_' + (j + arr[7])].pos_y = this.percentY(- this.tapis.height / 5 + pos[k] * element_mg_top + pos[k] * element_height);

          document.getElementById('clochette_' + (j + arr[0])).style.zIndex = "500";
          document.getElementById('clochette_' + (j + arr[1])).style.zIndex = "501";
          document.getElementById('clochette_' + (j + arr[2])).style.zIndex = "502";
          document.getElementById('clochette_' + (j + arr[3])).style.zIndex = "503";
          document.getElementById('clochette_' + (j + arr[4])).style.zIndex = "504";
          document.getElementById('clochette_' + (j + arr[5])).style.zIndex = "505";
          document.getElementById('clochette_' + (j + arr[6])).style.zIndex = "506";
          document.getElementById('clochette_' + (j + arr[7])).style.zIndex = "507";

          k = k + 1;
        }

      }

    },
    getTapisPosition() {
      var tapis_element = document.getElementById('img_tapis');
      const rect        = tapis_element.getBoundingClientRect();
      this.tapis.top    = rect.top;
      this.tapis.right  = rect.right;
      this.tapis.left   = rect.left;
      this.tapis.width  = rect.width;
      this.tapis.bottom = rect.bottom;
      this.tapis.height = rect.height;
    },
    onDown(e) {
      var element    = e.currentTarget;
      this.element.clicked = element.id;
      element     = null;
    },
    moveAt() {
      var element_id = this.element.clicked;
      if (element_id && this.element.clicked !== false) {
        var element     = document.getElementById(element_id).getBoundingClientRect();
        this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.width/2));
        this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.height/2));

        if ((this[element_id].pos_x * this.tapis.width) < 0) {
          this[element_id].pos_x = 0 ;
        }
        if ((this[element_id].pos_y * this.tapis.height) < 0) {
          this[element_id].pos_y = 0 ;
        }

        if ((this[element_id].pos_x * this.tapis.width) + element.width  > this.tapis.width) {
          this[element_id].pos_x = this.percentX(this.tapis.width -  element.width);
        }

        if ((this[element_id].pos_y * this.tapis.height) + element.height > this.tapis.height) {
          this[element_id].pos_y = this.percentY(this.tapis.height - element.height);
        }
        element     = null;
        element_id  = null;
      }
    },
    onUp(e) {
      // e.preventDefault();
      var element    = e.currentTarget;
      var element_id = element.id;
      this[element_id].pos_x = this.percentX(this.mouse_position.pox_x - this.tapis.left - (element.offsetWidth/2));
      this[element_id].pos_y = this.percentY(this.mouse_position.pox_y - this.tapis.top - (element.offsetHeight/2));
      this.element.clicked = false;
      element     = null;
      element_id  = null;
    },
    onMouseMove(e) {
      // e.preventDefault();
        var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
        var element    = e.currentTarget;
        this.mouse_position.pox_x = evt.pageX;
        this.mouse_position.pox_y = evt.pageY;
        if (element !== 'undefined') {
          this.moveAt();
        }
        evt     = null;
        element  = null;
    },
    percentX(x){
      return Math.round(1000 * x/this.tapis.width)/1000;
    },
    percentY(y){
      return Math.round(10000 * y/this.tapis.height)/10000;
    },
    selectedSound(e){

      var element    = e.currentTarget;
      var element_id = element.id;
      console.log('this.element.clicked' + this.element.clicked);
      var sound_path = this[element_id].sound;
      const audio = new Audio(require('../../assets/audio/les-clochettes/mp4/' + sound_path + '.mp4')).play();

      element = null;
      element_id = null;
      sound_path = null;

    },
    shake(e){
      var evt = e.type === 'touchstart' ? e.changedTouches[0] : e;
      var element    = e.currentTarget;
      setTimeout( () => {
        element.classList.add('shake');
      }, 1000)
      setTimeout( () => {
        element.classList.remove('shake');
      }, 3500)
    },
    shuffleAll(){
      this.shuffle_values.value = true;
      this.initPositions();
      this.shuffle_values.value = null;
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
        }

        return array;
      }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.container{
  width: calc(100% - 40px);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.tapis{
  display: block;
  position: relative;
  overflow: hidden;
}
.tapis img:first-child {
  margin: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.clochette_1{
  display: block;
  width: 9%;
  height: auto;
  position: absolute;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.btn {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  margin: 20px;
}
.btn:hover {
  background-color: white; /* Green */
  color: #000;
  border: solid 1px #4CAF50;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.shake {
 /* Start the shake animation and make the animation last for 0.5 seconds */
 animation: shake 0.5s;

 /* When the animation is finished, start again */
 animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(2px, 2px) rotate(0deg); }
  10% { transform: translate(-2px, -4px) rotate(-3deg); }
  20% { transform: translate(-6px, 0px) rotate(3deg); }
  30% { transform: translate(6px, 4px) rotate(0deg); }
  40% { transform: translate(2px, -2px) rotate(3deg); }
  50% { transform: translate(-2px, 4px) rotate(-3deg); }
  60% { transform: translate(-6px, 2px) rotate(0deg); }
  70% { transform: translate(6px, 2px) rotate(-3deg); }
  80% { transform: translate(-2px, -2px) rotate(3deg); }
  90% { transform: translate(2px, 4px) rotate(0deg); }
  100% { transform: translate(2px, -4px) rotate(-3deg); }
}

</style>
