<template>
  <TablePythagore/>
</template>

<script>
// @ is an alias to /src
import TablePythagore from '@/components/table-de-pythagore/TablePythagore.vue'

export default {
  name: 'TablePythagorePage',
  components: {
    TablePythagore
  }
}
</script>
