<template>
  <SoustractionDynamique/>
</template>

<script>
// @ is an alias to /src
import SoustractionDynamique from '@/components/SoustractionDynamique.vue'

export default {
  name: 'SoustractionDynamiquePage',
  components: {
    SoustractionDynamique
  }
}
</script>
