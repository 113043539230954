<template>
  <AdditionDynamique/>
</template>

<script>
// @ is an alias to /src
import AdditionDynamique from '@/components/AdditionDynamique.vue'

export default {
  name: 'AdditionDynamiquePage',
  components: {
    AdditionDynamique
  }
}
</script>
